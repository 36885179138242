.pageBox {
  width: 100%;
  background-image: linear-gradient(#302b24, rgba(9, 14, 16, 0));
  background-size: 100% 20%;
  background-repeat: no-repeat;
}

.pageContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding-top: 170px;
  padding-bottom: 20px;
}
