.wrapper {
  width: 70%;
  max-width: 1000px;
  min-width: 300px;
}

.mainInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.mainInfoAvatar {
  position: absolute;
  left: 50%;
  top: 32%;
  transform: translate(-50%, -50%);
}

.statsLine {
  display: flex;
  justify-content: space-between;
}

.rank {
  font-family: zombiefont;
  color: #d5f96f;
  font-size: 30px;
}

.userName {
  display: flex;
  align-items: center;
}

.statsBlock {
  display: flex;
  justify-content: center;
  width: 50%;
  border-bottom: 2px solid #534d3f;
  padding: 10px;
  &:first-child {
    border-right: 2px solid #534d3f;
  }
}

.avatarContainer {
  position: relative;
}

.avatarWrapper {
  width: 200px;
}

.avatar {
  max-width: 66px;
  position: absolute;
  left: 50%;
  top: 4.5%;
  transform: translateX(-50%);
}
